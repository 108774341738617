import React from 'react'
import moment from 'moment'

const HeightBox = ({ height }) => {
    return (
        <div style={{ height: `${height}px` }}></div>
    )

}

export default HeightBox