import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import BlogRoll from '../components/BlogRoll'
import SliderForderungen from '../components/SliderForderungen'
import SliderPartner from '../components/SliderPartner'
import HeightBox from '../components/HeightBox'
import Map from '../components/Map'

export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => (
    <div>
      <div
        className="hero hero-index has-overlay has-overlay-full background--image background--fixed"
      >
        <div className="container level">
          <section>
            <h1>
              Verkehrswende <br></br>für <b>Bayreuth.</b>
            </h1>
            <h3 className="is-size-5-mobile is-size-5-tablet is-size-4-widescreen">
              {subheading}
            </h3>
            <div className="buttons is-centered are-medium" style={{ margin: '3em 0' }}>
              <Link to="/unterschreiben" className="button has-shadow is-primary">Ergebnis</Link>
              <Link to="/ueber-uns" className="button has-shadow is-white">Über uns</Link>
            </div>
          </section>
        </div>
      </div>

      <section>
        <div className="columns">
          <div className="column">

          </div>
          <div className="column">

          </div>
        </div>
      </section>


      <section className="has-overlay has-overlay-dark section--gradient background--image background--fixed has-text-white level" style={{
        minHeight: '800px',
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
          })`
      }}>
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-half has-text-right">
                <h1 className="title is-1 has-text-white">Wir haben <b>5312 Unterschriften</b> gesammelt.</h1>
                <h2 className="subtitle has-text-white">Von Februar bis August 2020</h2>
                <Link class="button has-shadow is-primary" to="/ueber-uns">Über uns</Link>
              </div>
              <div className="column is-one-quarter">
                <div className="level">
                  <p className="content">
                    Nach dem Vorbild anderer Städte wie Berlin, Bamberg, München und vielen weiteren, in denen erfolgreiche Radentscheide durchgeführt wurden, haben wir auch in Bayreuth diesen Schritt gewagt.
                  Der Radentscheid ist ein <b>Bürgerbegehren</b>, welches zum Ziel hat den Radverkehr zu fördern.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="">
        {<SliderForderungen />}
      </section>

      <HeightBox height="100" />

      <section>
        <div className="container">
          <h2 className="title" id="sammelstellen">
            Unsere Sammelstellen
                                        </h2>
                                        <p>Wir danken allen Sammelstellen und weiteren Unterstützern, die uns bei der Durchführung des Bergerbegehrems geholfen haben!</p>
        </div>
      </section>

      <HeightBox height="100" />

      <section>
        {<SliderPartner />}
      </section>
      <HeightBox height="100" />
      <section class="section">
        <div className="container">
          <div className="columns">
            <div className="column">
              <div className="column is-12">
                <h3 className="title is-1">
                  Aktuelles
                </h3>
                <BlogRoll />
                <div className="column is-12 has-text-centered">
                  <Link className="button" to="/blog">
                    Mehr
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div >
  )

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`
