import React from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'
import { HTMLContent } from '../components/Content'
import Slider from "react-slick"
import Img from "gatsby-image"

class SliderPartner extends React.Component {

  render() {

    const { data } = this.props
    const { edges: partners } = data.allMarkdownRemark
    const settings = {
      dots: true,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 3,
      autoplay: true,
      responsive: [
        {
          breakpoint: 980,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }
      ],
    }

    return (
      <div className="section container slider-partner">
        <h3 class="title is-1">Uns unterstützende Verbände und Organisationen</h3>
        <Slider {...settings}>
          {partners &&
            partners.map(({ node: partner }) => (
              <SingleSlide key={partner.id} partner={partner} />
            ))
          }
        </Slider>

      </div>
    )
  }
}

class SingleSlide extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { partner } = this.props
    return (
      <a href={partner.frontmatter.website} title={partner.frontmatter.title}>
        <div style={{ padding: '1em', display: 'flex', justifyContent: 'center' }}>
          <Img
            fixed={partner.frontmatter.logo.childImageSharp.fixed}
            objectFit="contain"
            objectPosition="50% 50%"
            alt={partner.frontmatter.title}
          />
        </div >
      </a >
    )
  }
}



export default () => (
  <StaticQuery query={graphql`
    {
      allMarkdownRemark(
        limit: 1000, 
        sort: {order: ASC, fields: [frontmatter___forderungsnummer]},
        filter: {frontmatter: {contentType: {eq: "partner"}}},
        ) {
        edges {
          node {
            id
            frontmatter {
              title
              website
              logo {
                childImageSharp {
                  fixed(width:130) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
      `} render={(data, count) => <SliderPartner data={data} count={count} />} />
) 