import React from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'
import { HTMLContent } from '../components/Content'
import Img from "gatsby-image"

class SliderForderungen extends React.Component {

  render() {

    const { data } = this.props
    const { edges: forderungen } = data.allMarkdownRemark

    return (
      <div class="section forderungen">
        <div className="columns is-multiline" >
          {forderungen &&
            forderungen.map(({ node: forderung }) => (
              <SingleSlide key={forderung.id} forderung={forderung} />
            ))
          }

        </div>
      </div>
    )
  }
}

class SingleSlide extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false
    }
  }

  __toggleModal(e) {
    this.setState({
      modalOpen: !this.state.modalOpen
    })
  }

  render() {
    const { forderung } = this.props
    console.log(forderung)
    return (
      <article className="column is-flex is-8 is-half-tablet is-half-desktop is-one-third-widescreen is-one-fifth-fullhd" onClick={(e) => this.__toggleModal(e)}>
        <div className="card forderung" >
          <div class="card-content">
            <span>{`${forderung.frontmatter.forderungsnummer}`}.</span>
            <h1 className="title">{forderung.frontmatter.title}.</h1>
            <p className="">{forderung.frontmatter.description}</p>
            {forderung.frontmatter.featuredimage ? (
              <Img
                fixed={forderung.frontmatter.featuredimage.childImageSharp.fixed}
                objectFit="contain"
                objectPosition="50% 50%"
                style={{ position: "absolute" }}
              />
            ) : null}

          </div>
        </div>
        {forderung.html ? (
          <div className={`modal ${this.state.modalOpen ? 'is-active' : ''}`}>
            <div className="modal-background"></div>
            <div className="modal-content">
              <div className="card">
                <div className="card-content content">
                  <h2 className="title">{forderung.frontmatter.title}.</h2>
                  <HTMLContent content={forderung.html} />
                  <Link style={{ marginTop: '2em' }} className="btn" to="/unterschreiben">Jetzt unterschreiben</Link>
                </div>
              </div>
            </div>
            <button className="modal-close is-large" aria-label="close"></button>
          </div>
        ) : null}

      </article>
    )
  }
}



export default () => (
  <StaticQuery query={graphql`
    {
      allMarkdownRemark(
        limit: 1000, 
        filter: {frontmatter: {contentType: {eq: "forderung"}}},
        sort: { order: ASC, fields: [frontmatter___forderungsnummer] }
        
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              templateKey
              title
              description
              forderungsnummer
              featuredimage {
                childImageSharp {
                  fixed(width:180) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
            html
          }
        }
      }
    }
      `} render={(data, count) => <SliderForderungen data={data} count={count} />} />
) 